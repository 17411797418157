import { Col, Row } from 'antd';
import { H1, TextRegular } from '../components/shared/typography';
import { SiteLayout, StyledContent } from '../components/layout/layout';

import { IAboutPage } from '../components/shared/contentfu.interface';
import { PageContext } from '../pageContext';
import React from 'react';
import Seo from '../components/Seo';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

const StyledColHead = styled.h1`
  width: 100%;
  margin: 1rem;
  text-align: center;
  font-size: 32px;
  font-weight: 400;
`;

const StyledCert = styled.img`
  object-fit: cover;
  height: 100px;
  margin: 0.5rem;
`;

const StyledColImg = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

interface Props {
  pageContext: PageContext;
  data: {
    allContentfulAboutPage: {
      nodes: IAboutPage[];
    };
  };
}

const AboutPage: React.FC<Props> = ({ pageContext, data }: Props) => {
  const content = data.allContentfulAboutPage.nodes[0];

  return (
    <SiteLayout
      locale={pageContext.locale}
      componentName={pageContext.componentName}
    >
      <Seo
        title="Om HighView Aps"
        image={'/HighView-Security-text-tlf.jpg'}
        lang={pageContext.locale}
        keywords={[
          'AIA',
          'ADK',
          'Privat',
          'Sikkerhed',
          'IT Sikkerhed',
          'NOX alarmanlæg',
          'Jablotron JA100',
          'HighView',
          'Overvågning',
          'Erhvers sikkerhed',
          'adgangskontrolanlæg',
        ]}
        pathname="/om"
      />
      <StyledContent>
        <Row
          style={{
            padding: '1rem',
          }}
          gutter={[20, 20]}
        >
          <Col xs={24} lg={12} style={{ alignSelf: 'center' }}>
            <H1 style={{ textAlign: 'center' }}>{content.title}</H1>
            <TextRegular>{content.text.text}</TextRegular>
          </Col>
          <Col xs={24} lg={12}>
            <StyledColImg src="../../logo.png" />
          </Col>
          <Col
            span={24}
            style={{
              marginTop: '3rem',
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
            }}
          >
            <StyledColHead>Certificeringer</StyledColHead>
            <StyledCert src="https://www.presentationproducts.com/wp-content/uploads/2014/07/Microsoft-Silver1.jpg" />
            <StyledCert src="https://docs.microsoft.com/en-us/media/learn/certification/badges/mcsa-badge.svg" />
            <StyledCert src="/iso9001.png" />
          </Col>
        </Row>
      </StyledContent>
    </SiteLayout>
  );
};

export default AboutPage;

export const query = graphql`
  query AboutPageQuery {
    allContentfulAboutPage {
      nodes {
        title
        text {
          text
        }
      }
    }
  }
`;
